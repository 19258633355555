var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BrandChooseWindow'),_c('HeaderTwo'),_c('SliderFour'),_c('div',{staticClass:"rn-column-area rn-section-gap"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"8","md":"8","lg":"8","cols":"12"}},[_c('h2',{staticClass:"heading-title",staticStyle:{"font-size":"30px"}},[_vm._v(" Οι Συναλλαγές μου ")])]),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"align":"right"}},[_c('div',{staticClass:"borderPoints",style:(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el === 'White'
                ? { 'border-color': 'black' }
                : {
                    'border-color':
                      _vm.user.user_points_info&&_vm.user.user_points_info.current_package.color,
                  }),attrs:{"xs":"12","sm":"4","md":"4","lg":"4","cols":"12","align":"right"}},[_c('h2',{staticClass:"heading-title",staticStyle:{"font-size":"30px"},style:(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el === 'White'
                  ? { color: 'black' }
                  : { color: _vm.user.user_points_info&&_vm.user.user_points_info.current_package.color })},[_vm._v(" "+_vm._s(_vm.user.total_points)+" πόντοι ")]),_c('p',[_vm._v(" "+_vm._s(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el)+" ")])])]),_c('v-col',{staticClass:"d-block d-sm-none",attrs:{"align":"left"}},[_c('div',{staticClass:"borderPointsSmall",style:(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el === 'White'
                ? { 'border-color': 'black' }
                : {
                    'border-color':
                      _vm.user.user_points_info&&_vm.user.user_points_info.current_package.color,
                  }),attrs:{"xs":"12","sm":"4","md":"4","lg":"4","cols":"12","align":"left"}},[_c('h2',{staticClass:"heading-title",staticStyle:{"font-size":"30px"},style:(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el === 'White'
                  ? { color: 'black' }
                  : { color: _vm.user.user_points_info&&_vm.user.user_points_info.current_package.color })},[_vm._v(" "+_vm._s(_vm.user.total_points)+" πόντοι ")]),_c('p',[_vm._v(" "+_vm._s(_vm.user.user_points_info&&_vm.user.user_points_info.current_package.title.el)+" ")])])])],1),_c('Transactions')],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }