<template>
  <div>
    <BrandChooseWindow />
    <HeaderTwo />
    <SliderFour />
    <div class="rn-column-area rn-section-gap">
      <v-container>
        <v-row>
          <v-col xs="12" sm="8" md="8" lg="8" cols="12">
            <h2 class="heading-title" style="font-size: 30px">
              Οι Συναλλαγές μου
            </h2>
          </v-col>
          <v-col class="d-none d-md-block" align="right">
            <div
              xs="12"
              sm="4"
              md="4"
              lg="4"
              cols="12"
              align="right"
              class="borderPoints"
              :style="
                user.user_points_info&&user.user_points_info.current_package.title.el === 'White'
                  ? { 'border-color': 'black' }
                  : {
                      'border-color':
                        user.user_points_info&&user.user_points_info.current_package.color,
                    }
              "
            >
              <h2
                :style="
                  user.user_points_info&&user.user_points_info.current_package.title.el === 'White'
                    ? { color: 'black' }
                    : { color: user.user_points_info&&user.user_points_info.current_package.color }
                "
                class="heading-title"
                style="font-size: 30px"
              >
                {{ user.total_points }} πόντοι
              </h2>
              <p>
                {{ user.user_points_info&&user.user_points_info.current_package.title.el }}
              </p>
            </div>
          </v-col>
          <v-col class="d-block d-sm-none" align="left">
            <div
              xs="12"
              sm="4"
              md="4"
              lg="4"
              cols="12"
              align="left"
              class="borderPointsSmall"
              :style="
                user.user_points_info&&user.user_points_info.current_package.title.el === 'White'
                  ? { 'border-color': 'black' }
                  : {
                      'border-color':
                        user.user_points_info&&user.user_points_info.current_package.color,
                    }
              "
            >
              <h2
                :style="
                  user.user_points_info&&user.user_points_info.current_package.title.el === 'White'
                    ? { color: 'black' }
                    : { color: user.user_points_info&&user.user_points_info.current_package.color }
                "
                class="heading-title"
                style="font-size: 30px"
              >
                {{ user.total_points }} πόντοι
              </h2>
              <p>
                {{ user.user_points_info&&user.user_points_info.current_package.title.el }}
              </p>
            </div>
          </v-col>
        </v-row>

        <Transactions />
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderTwo from "../components/header/HeaderOnePage";
import Footer from "../components/footer/FooterTwo";
import Transactions from "../components/information/Transactions";
import SliderFour from "../components/slider/SliderFour";
import BrandChooseWindow from "../components/brand/BrandChooseWindow";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderTwo,
    Footer,
    SliderFour,
    Transactions,
    BrandChooseWindow,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    if (!this.user) {
      await this.getUser();
      if (!this.user) {
        this.$router.push("/");
        return;
      }
    }
  },
  methods: {
    ...mapActions(["getUser"]),
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style scoped>
.borderPoints {
  padding: 15px;
  border: 1px;
  border-style: solid;
  border-color: black;
  max-width: 300px;
  border-radius: 30px;
}
.borderPointsSmall {
  padding: 15px;
  border: 1px;
  border-style: solid;
  border-color: black;
  max-width: 260px;
  border-radius: 30px;
}
</style>
