<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="text-white mb--50 mt--50">
          <div v-if="!(transactions&&transactions.transactions.length)">
            Δεν υπάρχουν δεδομένα
          </div>
          <div v-else class="text-white mb--50">
            <v-row class="mb--20">
              <v-col>
                <p style="font-family: 'PFBagueSansPro-Bold';">Προϊόν</p>
              </v-col>
              <v-col>
                <p style="font-family: 'PFBagueSansPro-Bold';">Νοσοκομείο</p>
              </v-col>
              <v-col>
                <p style="font-family: 'PFBagueSansPro-Bold';">Αξία</p>
              </v-col>
              <v-col>
                <p style="font-family: 'PFBagueSansPro-Bold';">Πόντοι</p>
              </v-col>
              <v-col class="d-none d-sm-block">
                <p style="font-family: 'PFBagueSansPro-Bold';">Ημ/νία</p>
              </v-col>
            </v-row>
            <hr />
            <div
              id="transactionsList"
              v-for="(transaction, i) in transactions&&transactions.transactions"
              :key="i"
            >
              <v-row class="pb--30 pt--30" style="border-bottom: 0.2px solid  !important;"
                v-for="(transaction_items, x) in transaction.transaction_items"
                :key="x"
              >
                <!-- {{ transaction }} -->
                <v-col>
                  <p>
                    {{ transaction_items.product_name }}
                  </p>
                  <p style="margin-top: -15px;" class="d-block d-sm-none">{{ momentDate(transaction.created_at) }}</p>
                </v-col>
                <v-col>
                  <p>
                    {{ transaction.store.name }}
                  </p>
                </v-col>
                <v-col>
                  <p
                    v-if="
                      [
                        'order_via_mobile_app',
                        'order_pos',
                        'order_via_android_pos',
                        'order_via_store_manager',
                      ].includes(transaction.transaction_type_system)
                    "
                  >
                    {{
                      transaction_items.total_row_price +
                        " " +
                        transaction.currency
                    }}
                  </p>
                  <p
                    v-else-if="
                      ['refund'].includes(transaction.transaction_type_system)
                    "
                  >
                    {{
                      "-" +
                        transaction_items.total_row_price +
                        " " +
                        transaction_items.currency
                    }}
                  </p>
                  <p v-else>{{ "-" }}</p>
                </v-col>
                <v-col>
                  <p>
                    {{ transaction_items.total_row_points + " πόντοι" }}
                  </p>
                </v-col>
                <v-col class="d-none d-sm-block">
                  <p>
                    {{ moment(transaction.created_at) }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <b-pagination
      class="mt--100"
      v-if="this.transactions&&this.transactions.pagination.total_pages > 1"
      v-model="currentPage"
      v-on:click.native="scrollToTop"
      :per-page="this.transactions.pagination.items_per_page"
      :total-rows="this.transactions.pagination.total"
      aria-controls="transactionsList"
      align="center"
    ></b-pagination>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    moment,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["transactions"]),
    ...mapGetters(["user"]),
    rows() {
      return this.transactions.length;
    },
  },
  watch: {
    currentPage: async function(newQuestion, oldQuestion) {
      this.currentPage = newQuestion;
      await this.getTransactions(newQuestion);
    },
  },
  async created() {
    //  if(!this.user){ this.$router.push('/home') }

    if (!this.user) {
      await this.getUser();
      if (!this.user) {
        this.$router.push("/");
        return;
      }
    }
    await this.getTransactions(1)
    console.log(this.transactions);
  },
  methods: {
    ...mapActions(["getTransactions"]),
    ...mapActions(["getUser"]),
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    moment: function(created_at) {
      return moment(String(created_at)).format("DD-MM-YYYY, HH:mm");
    },
    momentDate: function(created_at) {
      return moment(String(created_at)).format("DD-MM-YYYY");
    },
  },
};
</script>
