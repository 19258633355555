var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-white mb--50 mt--50"},[(!(_vm.transactions&&_vm.transactions.transactions.length))?_c('div',[_vm._v(" Δεν υπάρχουν δεδομένα ")]):_c('div',{staticClass:"text-white mb--50"},[_c('v-row',{staticClass:"mb--20"},[_c('v-col',[_c('p',{staticStyle:{"font-family":"'PFBagueSansPro-Bold'"}},[_vm._v("Προϊόν")])]),_c('v-col',[_c('p',{staticStyle:{"font-family":"'PFBagueSansPro-Bold'"}},[_vm._v("Νοσοκομείο")])]),_c('v-col',[_c('p',{staticStyle:{"font-family":"'PFBagueSansPro-Bold'"}},[_vm._v("Αξία")])]),_c('v-col',[_c('p',{staticStyle:{"font-family":"'PFBagueSansPro-Bold'"}},[_vm._v("Πόντοι")])]),_c('v-col',{staticClass:"d-none d-sm-block"},[_c('p',{staticStyle:{"font-family":"'PFBagueSansPro-Bold'"}},[_vm._v("Ημ/νία")])])],1),_c('hr'),_vm._l((_vm.transactions&&_vm.transactions.transactions),function(transaction,i){return _c('div',{key:i,attrs:{"id":"transactionsList"}},_vm._l((transaction.transaction_items),function(transaction_items,x){return _c('v-row',{key:x,staticClass:"pb--30 pt--30",staticStyle:{"border-bottom":"0.2px solid  !important"}},[_c('v-col',[_c('p',[_vm._v(" "+_vm._s(transaction_items.product_name)+" ")]),_c('p',{staticClass:"d-block d-sm-none",staticStyle:{"margin-top":"-15px"}},[_vm._v(_vm._s(_vm.momentDate(transaction.created_at)))])]),_c('v-col',[_c('p',[_vm._v(" "+_vm._s(transaction.store.name)+" ")])]),_c('v-col',[(
                    [
                      'order_via_mobile_app',
                      'order_pos',
                      'order_via_android_pos',
                      'order_via_store_manager' ].includes(transaction.transaction_type_system)
                  )?_c('p',[_vm._v(" "+_vm._s(transaction_items.total_row_price + " " + transaction.currency)+" ")]):(
                    ['refund'].includes(transaction.transaction_type_system)
                  )?_c('p',[_vm._v(" "+_vm._s("-" + transaction_items.total_row_price + " " + transaction_items.currency)+" ")]):_c('p',[_vm._v(_vm._s("-"))])]),_c('v-col',[_c('p',[_vm._v(" "+_vm._s(transaction_items.total_row_points + " πόντοι")+" ")])]),_c('v-col',{staticClass:"d-none d-sm-block"},[_c('p',[_vm._v(" "+_vm._s(_vm.moment(transaction.created_at))+" ")])])],1)}),1)})],2)])])],1),(this.transactions&&this.transactions.pagination.total_pages > 1)?_c('b-pagination',{staticClass:"mt--100",attrs:{"per-page":this.transactions.pagination.items_per_page,"total-rows":this.transactions.pagination.total,"aria-controls":"transactionsList","align":"center"},nativeOn:{"click":function($event){return _vm.scrollToTop($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }